import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CollectionGuard } from 'akita-ng-fire';

import { MemberAreaService } from '../state/member-area.service';
import { MemberAreaState } from '../state/member-area.store';

@Injectable({
  providedIn: 'root',
})
export class MemberAreaGuard extends CollectionGuard<MemberAreaState> {
  constructor(protected service: MemberAreaService) {
    super(service);
  }

  protected sync(next: ActivatedRouteSnapshot) {
    return this.service.syncActive({ id: next.params.schoolId });
  }

  canDeactivate(): boolean {
    this.service.setActive(null);

    return super.canDeactivate();
  }
}
